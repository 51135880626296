import * as React from 'react';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';


// import MyColors from 'codb-core/constants/my-theme';


export default class extends React.Component {
	_onRemove = () => {
		this.props.onChange(this.props.index, null);
	}

	// _onChangeInput = (txt) => {
	// 	this.props.onChange(this.props.index, Object.assign({}, this.props.data, {
	// 		option: txt
	// 	}))
	// }

	_onChange = (e) => {
		const txt = e.target.value;

		this.props.onChange(this.props.index, Object.assign({}, this.props.data, {
			option: txt
		}))
	}


	render() {
		const option = this.props.data.option;
		const value = !!option ? option : '';

		return (
			<div className="g-flex-h-sb-c g-padding-6-10">
				<div style={{flexDirection: 'row', flex: 1, alignItems: 'center', marginRight: 20, marginBottom: 10}}>
					<Input
						value={value}
						onChange={this._onChange}
						maxLength={32}
						fullWidth={true}
						placeholder={"Input search words here"}
					/>
				</div>

				<Icon
					color="error"
					onClick={this._onRemove}
					className="g-pointer"
				>close</Icon>
			</div>
		)
	}
}