import React from 'react';
// import _ from 'lodash';
// import Icon from '@material-ui/core/Icon';
///
import './styles.css';
///
import StarSVG from '../../assets/star.svg';
import StarBorderSVG from '../../assets/star_border.svg';
import StarHalfSVG from '../../assets/star_half.svg';
///
// import MyColors from 'codb-core/constants/my-theme';
///

export default class extends React.Component {
	_onPressItem = () => {
		if (!this.props.onChange) { return; }

		const {
			value,
			order
		} = this.props;

		// Click current full star
		if (order === value - 1) {
			this.props.onChange(value - 0.5)
		} else if (order === value - 0.5) {
			this.props.onChange(value - 0.5)
		} else {
			this.props.onChange(order + 1)
		}
	}

	_getIconName = () => {
		const {
			value,
			order
		} = this.props;

		if (order + 1 <= value) {
			return 'star';
		} else if (order + 0.5 === value) {
			return 'star-half'
		} else {
			return 'star-border';
		}
	}

	_getIconComp = () => {
		const {
			value,
			order
		} = this.props;

		if (order + 1 <= value) {
			return StarSVG;
		} else if (order + 0.5 === value) {
			return StarHalfSVG;
		} else {
			return StarBorderSVG;
		}
	}

/*
<Icon
				style={{color: MyColors.yellow_heavy}}
				onClick={this._onPressItem}
				className="g-pointer"
			>{this._getIconName()}</Icon>
*/
	render() {
		// const {
		// 	onChange
		// } = this.props;

		const Comp = this._getIconComp();

		return (
			<img
				onClick={this._onPressItem}
				src={Comp}
				alt="rate"
			/>
		)
	}
}