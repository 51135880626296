import React from 'react';
// import _ from 'lodash';
import * as Validator from 'validator';
import YouTube from 'react-youtube';

import * as UtilsUrl from 'codb-core/utils/url';
///


export default class extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		}
	}

	// async componentDidMount() {
		
	// }

	_onReady = (event) => {
		event.target.pauseVideo();
	}

	render() {
		const { data } = this.props;
		if (!data) { return null; }
		const { input } = data;

		if (UtilsUrl.checkIfYoutube(input)) {
			const id = UtilsUrl.getYoutubeId(input);

			if (id) {
				return (
					<YouTube
						videoId={id}
						opts={{
							width: '100%'
						}}
					/>
				)
			}
		}

		if (Validator.isURL(input)) {
			return (
				<div>
					<a  style={{color: 'white'}} target="_blank" href={input} rel="noopener noreferrer">
						{input}
					</a>
				</div>
				
			)
		}

		return (
			<div style={{color: 'white'}}>{input}</div>
		)
	}
}