import React from 'react';
// import _ from 'lodash';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';

import './styles.css';

import FilterNavigatorItem from '../filter-navigator-item';
import FilterItem from '../filter-item';
import FilterUserInput from '../filter-user-inpupt';

// import * as UtilsItems from 'codb-core/utils/generic-item';
// import * as UtilsFilter from 'codb-core/utils/filter';
import * as RemoteItemsFilterActions from 'codb-core/actions/remote-items-filter';
import I18n from 'codb-core/i18n';


class Screen extends React.Component {
	componentDidMount() {
		this.props.onInit();
	}
	componentWillUnmount() {
		this.props.onClear();
	}
	_onConfirm = () => {
		this.props.onConfirm();
		this.props.onClose();
	}
	render() {
		const {
			options,
			metas,
			focus_meta,
			selected,
			filled_metas,
			current_user_input,
			items
		} = this.props;

		return (
			<div className="my-filter">
				<div className="g-flex-h-sb-c g-padding-6-10 g-margin-b-20">
					<Icon className="g-pointer" onClick={this.props.onClose}>close</Icon>

					<Icon className="g-pointer" onClick={this.props.onReset}>delete</Icon>

					<Icon className="g-pointer" onClick={this._onConfirm}>check</Icon>
				</div>

				{metas.length > 0 && (
					<div className="my-filter-header">
						{metas.map((v, k) => (
							<FilterNavigatorItem
								onSelect={this.props.onNavigate}
								focus={focus_meta}
								filled={filled_metas}
								data={v}
								key={k}
							/>
						))}
					</div>
				)}

				<div className="my-filter-body">
					<FilterUserInput
						meta={focus_meta}
						data={current_user_input}
						items={items}
						onChange={this.props.onChangeUserInput}
					/>

					{options.map((v, k) => (
						<FilterItem 
							data={v} 
							key={k}
							onSelect={this.props.onSelect}
							onUnselect={this.props.onUnselect}
							meta={focus_meta}
							items={items}
							selected={selected}
						/>
					))}

					{options.length === 0 && (
						<div className="g-padding-10 g-text-align-center">{I18n.t('Filter_No_Option')}</div>
					)}
				</div>
			</div>
		)
	}
}

export default connect(
	(state) => {
		const {
			options,
			selected,
			metas,
			focus_meta,
			filled_metas,
			current_user_input,
			items
		} = state.remoteItemsFilter;

		return {
			options,
			selected,
			metas,
			focus_meta,
			filled_metas,
			current_user_input,
			items
		}
	},

	(dispatch) => {
		return {
			onInit: () => {
				dispatch(RemoteItemsFilterActions.init());
			},
			onClear: () => {
				dispatch(RemoteItemsFilterActions.clear());
			},
			onConfirm: () => {
				requestAnimationFrame(() => {
					dispatch(RemoteItemsFilterActions.confirm());
				});
			},
			onSelect: (
				meta,
				option,
				compare
			) => {
				dispatch(RemoteItemsFilterActions.select(meta, option, compare));
			},
			onUnselect: (meta, option) => {
				dispatch(RemoteItemsFilterActions.unselect(meta, option));
			},
			onReset: () => {
				dispatch(RemoteItemsFilterActions.reset());
			},
			onNavigate: (meta) => {
				dispatch(RemoteItemsFilterActions.navigate(meta));
			},
			onChangeUserInput: (meta, changedUserInput) => {
				dispatch(RemoteItemsFilterActions.changeUserInput(meta, changedUserInput));
			}
		}
	}
)(Screen);