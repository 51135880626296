import React from 'react';

import './styles.css';

import ColumnsForDisplay from '../columns-for-display';

export default function({
	data
}) {
	if (!data) { return null; }

	const {
		label,
		content
	} = data;

	const {
		columns
	} = content;

	const labelText = label ? label.content : '';

	return (
		<div className="my-row">
			<div className="my-row-label">{labelText}</div>

			<ColumnsForDisplay data={columns} />
		</div>
	)
}