import * as React from 'react';
import _ from 'lodash';
import {Button} from '@material-ui/core';

import NumberRow from './number-row';

import * as UtilsArray from 'codb-core/utils/array';
import I18n from 'codb-core/i18n';

export default class extends React.Component {
	_onChangeRow = (index, changedRow) => {
		const { meta, data } = this.props;
		const { list } = data;

		if (changedRow === null) {
			const rmList = UtilsArray.removeByIndex(list, index);

			if (rmList.length === 0) {
				this.props.onChange(meta, null);
			} else {
				this.props.onChange(meta, Object.assign({}, data, {
					list: rmList
				}))
			}
		} else {
			this.props.onChange(this.props.meta, Object.assign({}, data, {
				list: UtilsArray.replaceByIndex(list, index, changedRow)
			}))
		}
			
	}

	_onAddRow = () => {
		const { data, meta } = this.props;
		const newRow = {
			option: 0,
			compare: {
				larger: false,
				equal: true,
				smaller: false
			}
		}

		if (data === null) {
			this.props.onChange(meta, {
				meta,
				list: [newRow]
			})
		} else {
			const clonedList = _.clone(data.list);
			clonedList.push(newRow);

			this.props.onChange(meta, Object.assign({}, data, {
				list: clonedList
			}))
		}
	}

	render() {
		const data = this.props.data;
		const list = !!data ? data.list : [];

		return (
			<div className="g-padding-6-10">
				{list.map((v, k) => (
					<NumberRow
						data={v}
						key={k}
						index={k}
						onChange={this._onChangeRow}
					/>
				))}

				<div className="g-flex-h-c-c">
					<Button variant="contained" onClick={this._onAddRow}>
						{I18n.t('Filter_Add_Custom_Query_Number')}
					</Button>
				</div>
					
			</div>
		)
	}
}