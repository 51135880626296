import React from 'react';
import _ from 'lodash';

import {
	Container,
	Modal
} from 'react-bootstrap'

import {
	Link
} from 'react-router-dom';

import { connect } from 'react-redux';

import Input from '@material-ui/core/Input';

// import API from '../../api';
import * as RemoteItemsActions from 'codb-core/actions/remote-items';
import * as RemoteFollowedGroupsActions from 'codb-core/actions/remote-followed-groups';
import * as RemoteGroupsActions from 'codb-core/actions/remote-groups';
import * as AppActions from 'codb-core/actions/app';
import MyColors from 'codb-core/constants/my-theme';
import * as UtilsRemoteGroup from 'codb-core/utils/remote-group';
import I18n from 'codb-core/i18n';
import * as UtilsFilter from 'codb-core/utils/filter';
///
import ItemRow from '../item-row';
import MobilePromotion from '../mobile-promotion';
import ItemsFilter from '../items-filter';
import LinkToApp from '../link-to-app';

import './styles.css';








class Screen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			is_loading: false,
			is_filter_open: false,

			search: ''
		}
	}
	
	async componentDidMount() {
		const id = this._getId();

		// this.setState({
		// 	is_loading: true
		// })

		// const items = await API.Items.load(id, [], [], 'APPROVED');
		
		// this.setState({
		// 	items,
		// 	is_loading: false
		// })

		if (id) {
			this.props.init({
				group_id: id,
				not_check_follow_status: true
			})
		}
	}

	componentWillUnmount() {
		this.props.clear();
	}

	_getId = () => {
		if (_.has(this.props, 'match.params.id')) {
			return this.props.match.params.id;
		} else {
			return null;
		}
	}

	_openFilter = () => {
		this.setState({
			is_filter_open: true
		})
	}

	_onCloseFilter = () => {
		this.setState({
			is_filter_open: false
		})
	}

	_onChangeSearch = (e) => {
		const txt = e.target.value;

		this.setState({
			search: txt
		})
	}

	render() {
		const {
			is_filter_open,
			search
		} = this.state;

		const {
			list: items,
			has_filtered,
			has_user_inputs,
			group,
			filter_user_inputs,
			filter_selected
		} = this.props;

		const isFinalFiltered = has_filtered || has_user_inputs;
		let filteredList = UtilsFilter.filter(items, filter_selected, filter_user_inputs);

		if (!!search) {
			filteredList = UtilsFilter.globalSearch(filteredList, search);
		}

		if (!group) { return null; }

		return (
			<Container>
				<div className="g-margin-t-10">
					<MobilePromotion />
				</div>
				
				<div className="g-margin-t-10">
					<LinkToApp link={UtilsRemoteGroup.getAppShareLink(this.props.group)} />
				</div>
				

				<div className="list">
					<div className="header">
						<Link to="/">
							<i className="material-icons md-36 md-light pointer">close</i>
						</Link>

						<Input
							value={search}
							onChange={this._onChangeSearch}
							maxLength={32}
							fullWidth={true}
							className="global-search"
							placeholder={I18n.t('Input_Search_Words_Here')}
							inputProps={{
								style: {
									color: 'white'
								}
							}}
						/>

						<i className="material-icons md-36 g-pointer" style={{color: isFinalFiltered ? MyColors.accent : 'white'}} onClick={this._openFilter}>filter_list</i>
					</div>

					<div className="content">
						{filteredList.map((v, k) => (
							<Link key={k} to={`/item/${v.id}`} className="g-pointer g-clear-link">
								<ItemRow data={v} />
							</Link>
						))}

						{filteredList.length === 0 && (
							<div className="g-empty-list-reminder">{isFinalFiltered ? I18n.t('Remote_Items_No_Data_Filtered') : I18n.t('Remote_Items_No_Data')}</div>
						)}
					</div>
				</div>

				<Modal
					show={is_filter_open}
					size="xl"
					centered
					dialogClassName="modal-90w"
					onHide={this._onCloseFilter}
					className="my-modal"
				>
					<Modal.Body>
						<ItemsFilter 
							onClose={this._onCloseFilter}
						/>
					</Modal.Body>
				</Modal>
					
			</Container>
		)
	}
};







export default connect(
	(state) => {
		const {
			app,
			remoteItems,
			// auth,
			remoteItemReview,
			remoteItemsFilter
		} = state;

		return {
			list: remoteItems.list,
			owner_pending_list: remoteItems.owner_pending_list,
			owner_menu_index: remoteItems.owner_menu_index,
			has_filtered: remoteItemsFilter.selected.length > 0,
			has_user_inputs: remoteItemsFilter.user_inputs.length > 0,

			is_loading: remoteItems.is_loading,
			is_reloading: remoteItems.is_reloading,
			is_loading_add: remoteItems.is_loading_add,
			is_loading_edit: remoteItems.is_loading_edit,
			is_loading_remove: remoteItems.is_loading_remove,
			is_loading_contribute: remoteItems.is_loading_contribute,
			is_loading_detach: remoteItems.is_loading_detach,
			is_loading_follow: remoteItems.is_loading_follow,
			is_loading_unfollow: remoteItems.is_loading_unfollow,
			is_loading_resubmit: remoteItems.is_loading_resubmit,

			is_loading_approve: remoteItemReview.loading_approve,
			is_loading_reject: remoteItemReview.loading_reject,

			// user_id: auth.user.uid,
			user_follow_status: remoteItems.user_follow_status,
			group: remoteItems.group,
			is_item_swiped: app.is_item_swiped,

			filter_selected: remoteItems.filter_selected,
			filter_user_inputs: remoteItems.filter_user_inputs,
		}
	},
	(dispatch) => {
		return {
			init: (opts) => {
				dispatch(RemoteItemsActions.init(opts))
			},
			clear: () => {
				dispatch(RemoteItemsActions.clear())
			},
			onAddItem: (groupId, props) => {
				dispatch(RemoteItemsActions.add(groupId, props))
			},
			onEditItem: (itemId, props) => {
				dispatch(RemoteItemsActions.edit(itemId, props))
			},
			onDeleteItem: (itemId) => {
				dispatch(RemoteItemsActions.remove(itemId))
			},
			onDetachItem: (itemId) => {
				dispatch(RemoteItemsActions.detach(itemId));
			},
			onResubmitItem: (itemId, groupData) => {
				dispatch(RemoteItemsActions.resubmit(itemId, groupData));
			},
			onReload: () => {
				dispatch(RemoteItemsActions.reload());
			},
			onFollow: (groupId) => {
				dispatch(RemoteFollowedGroupsActions.follow(groupId));
			},
			onUnfollow: (groupId) => {
				dispatch(RemoteFollowedGroupsActions.unfollow(groupId));
			},
			onSetItemSwiped: () => {
				dispatch(AppActions.setItemSwiped());
			},
			onContribute: (groupId, props, group) => {
				dispatch(RemoteItemsActions.contribute(groupId, props, group));
			},
			onUpdateOwnerMenuIndex: (index) => {
				dispatch(RemoteItemsActions.updateOwnerMenuIndex(index));
			},
			onHookGroup: (groupId) => {
				dispatch(RemoteGroupsActions.hook(groupId));
			},
			onUnhookGroup: (groupId) => {
				dispatch(RemoteGroupsActions.unhook(groupId));
			},
			resetGroupNewPendingItemsCounter: (groupId) => {
				dispatch(RemoteGroupsActions.resetNewPendingItemsCounter(groupId));
			}
		}
	}
)(Screen);