import React from 'react';

import ColumnForDisplay from '../column-for-display';

export default function ({data}) {
	if (!data || data.length === 0) { return null; }

	return (
		<div>
			{data.map((v, k) => (
				<ColumnForDisplay data={v} key={k} />
			))}
		</div>
	)
}