import React from 'react';
import {Button} from '@material-ui/core';
import MobileDetect from 'mobile-detect';

import I18n from 'codb-core/i18n';

import './styles.css';

export default function({
	link
}) {
	const md = new MobileDetect(window.navigator.userAgent);
	if (!md.mobile()) { return null; }

	return (
		<div className="g-flex-h-sb-c my-link-to-app-wrap">
			<div>{I18n.t('Link_to_App_Text')}</div>

			<Button onClick={() => window.location.replace(link)} variant="contained" color="primary">
				{I18n.t('Link_to_App_Btn_Text')}
			</Button>
		</div>
	)
}