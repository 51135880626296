import * as React from 'react';
import _ from 'lodash';

import FilterItemNumber from './number';
import FilterItemShortText from './short-text';
import FilterItemBoolean from './boolean';
import FilterItemRelation from './relation';

import ItemFilterOptionType from 'codb-core/constants/item-filter-option-type';

export default class extends React.Component {
	_getComp = () => {
		const type = _.get(this.props, 'meta.type', null);
		
		switch(type) {
			case ItemFilterOptionType.SHORT_TEXT: 
				return FilterItemShortText;
			case ItemFilterOptionType.NUMBER:
				return FilterItemNumber;
			case ItemFilterOptionType.BOOLEAN:
				return FilterItemBoolean;
			case ItemFilterOptionType.RELATION:
				return FilterItemRelation;
			case ItemFilterOptionType.RATE:
				return null;
			default:
				return undefined
		}
	}

	render() {
		// const filterType = this.props.meta.type;

		const Comp = this._getComp();

		return (
			<div>
				{!!Comp && (
					<Comp {...this.props} />
				)}

				{!Comp && (null)}
			</div>
		)
	}
}