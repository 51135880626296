
import React from 'react';

import * as UtilsTime from 'codb-core/utils/time';

import './styles.css';
import logo from '../../assets/ontology-icon-64.png';


export default class extends React.Component {
	// constructor(props) {
	// 	super(props);

	// 	this.state = {
	// 		items: null
	// 	}
	// }
	
	// async componentDidMount() {
	// }

	// componentWillUnmount() {
	// }

	render() {
		const {
			data
		} = this.props;

		let {
			title,
			img,
			count_followed,
			edit_at
		} = data;

		const finalUri = img && img.uri ? img.uri : logo;

		return (
			<div className="main-wrapper pointer">
				<div className="main">
					<img
						src={finalUri}
						width="44"
						height="44"
						className="main-icon"
						alt=""
					/>

					<div className="main-info">
						<div className="g-flex-h-sb-c">
							<b>{title}</b>

							{!!edit_at && (
								<div style={{fontSize: 12, color: 'gray'}}>{UtilsTime.getLightGap(edit_at)}</div>
							)}
						</div>
						

						<div>
							{count_followed > 0 && (
								<div className="follow">
									<i className="material-icons md-18 count-followed-icon">star</i>

									<small className="count-followed-num">{count_followed}</small>
								</div>
							)}
								
						</div>
					</div>
				</div>
			</div>
		)
	}
};