import * as React from 'react';
// import { Provider as ReduxProvider } from 'react-redux';
import {connect} from 'react-redux';

// import API from 'codb-core/api';
// import {setup as I18nSetup} from 'codb-core/i18n';

// import Store from '../store';
import Comp from '../components/app';


// const store = Store();
// API.setup();
// I18nSetup({
// 	locale: 'en'
// });



class Screen extends React.Component {
	render() {
		return (
			<Comp />
		)
	}
}


export default connect(
	(state) => {
		return {

		}
	},
	(dispatch) => {
		return {

		}
	}
)(Screen);