import React from 'react';
import _ from 'lodash';
///
import SingleRate from './single-rate';

export default class extends React.Component {
	// constructor(props) {
	// 	super(props);

	// 	this.state = {
	// 		is_loading: false,
	// 	}
	// }

	render() {
		const {
			max,
			value
		} = this.props;

		const list = _.fill(Array(max), true);

		return (
			<div className="ctn">
				{list.map((v, k) => (
					<SingleRate
						value={value}
						order={k}
						onChange={this.props.onChange}
						key={k}
					/>
				))}
			</div>
		)
	}
}