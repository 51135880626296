import * as React from 'react';
import {Button} from '@material-ui/core';
import _ from 'lodash';

import * as UtilsArray from 'codb-core/utils/array';
// import MyColors from 'codb-core/constants/my-theme';
import I18n from 'codb-core/i18n';
import RelationRow from './relation-row';

export default class extends React.Component {
	_onChangeRow = (index, changedRow) => {
		const { meta, data } = this.props;
		const { list } = data;

		if (changedRow === null) {
			const rmList = UtilsArray.removeByIndex(list, index);

			if (rmList.length === 0) {
				this.props.onChange(meta, null);
			} else {
				this.props.onChange(meta, Object.assign({}, data, {
					list: rmList
				}))
			}
		} else {
			this.props.onChange(this.props.meta, Object.assign({}, data, {
				list: UtilsArray.replaceByIndex(list, index, changedRow)
			}))
		}
			
	}

	_onAddRow = () => {
		const { data, meta } = this.props;
		const newRow = {
			option: '',
			label: null,
			compare: {
				equal: true,
				larger: false,
				smaller: false,
				include: false,
				on: -1
			}
		}

		if (data === null) {
			this.props.onChange(meta, {
				meta,
				list: [newRow]
			})
		} else {
			const clonedList = _.clone(data.list);
			clonedList.push(newRow);

			this.props.onChange(meta, Object.assign({}, data, {
				list: clonedList
			}))
		}
	}

	render() {
		const data = this.props.data;
		const list = !!data ? data.list : []

		return (
			<div className="g-padding-6-10">
				{list.map((v, k) => (
					<RelationRow
						data={v}
						key={k}
						index={k}
						onChange={this._onChangeRow}
						meta={this.props.meta}
						items={this.props.items}
					/>
				))}

				<div className="g-flex-h-c-c" style={{marginTop: 10}}>
					<Button variant="contained" onClick={this._onAddRow}>
						{I18n.t('Filter_Add_Custom_Query_Relation')}
					</Button>
				</div>
					
			</div>
		)
	}
}