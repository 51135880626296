import * as React from 'react';
// import _ from 'lodash';


import ItemFilterOptionType from 'codb-core/constants/item-filter-option-type';

import FilterUserInputNumber from './number';
import FilterUserInputShortText from './short-text';
import FilterUserInputLong from './long';
import FilterUserInputRelation from './relation';
import FilterUserInputRate from './rate';


export default class extends React.Component {
	render() {
		if (!this.props.meta) {
			return null;
		}

		const filterType = this.props.meta.type;

		if (filterType === ItemFilterOptionType.NUMBER) {
			return (
				<FilterUserInputNumber {...this.props} />
			)
		}

		if (filterType === ItemFilterOptionType.SHORT_TEXT) {
			return (
				<FilterUserInputShortText {...this.props} />
			)
		}

		if (filterType === ItemFilterOptionType.LONG) {
			return (
				<FilterUserInputLong {...this.props} />
			)
		}

		if (filterType === ItemFilterOptionType.RELATION) {
			return (
				<FilterUserInputRelation {...this.props} />
			)
		}

		if (filterType === ItemFilterOptionType.RATE) {
			return (
				<FilterUserInputRate {...this.props} />
			)
		}
		
		return null
	}
}