import * as React from 'react';

import * as UtilsItemFilterOption from 'codb-core/utils/item-filter-option';
import Checkbox from '@material-ui/core/Checkbox';

import SwitchBtn from '../switch-btn';


export default class extends React.Component {
	_getCompare = () => {
		return UtilsItemFilterOption.getCompare(this.props.selected, this.props.meta, this.props.data);
	}

	_onPress = () => {
		const compare = this._getCompare();

		if (compare !== null) {
			this.props.onUnselect(this.props.meta, this.props.data);
		} else {
			this._onSelect({
				on: false
			})
		}
	}

	_onSelect = (compare) => {
		this.props.onSelect(
			this.props.meta,
			this.props.data,
			compare
		);
	}

	_getChecked = () => {
		const compare = this._getCompare();
		return !!compare;
	}

	_onSwitchChange = (bool) => {
		if (this._getChecked()) {
			this._onSelect({
				on: bool
			})
		} else {
			if (bool === true) {
				this._onSelect({
					on: true
				})
			}
		}
	}

	_getSwitchValue = () => {
		const compare = this._getCompare();

		if (!!compare) {
			return compare.on;
		} else {
			return false;
		}
	}


	render() {
		return (
			<div className="g-flex-h-sb-c">
				<Checkbox
					checked={this._getChecked()}
					onChange={this._onPress}
					color="primary"
				/>

				<SwitchBtn value={this._getSwitchValue()} onValueChange={this._onSwitchChange} />
			</div>
		)
	}
}