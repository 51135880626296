import React from 'react';
import _ from 'lodash';
///
import Rate from '../rate';
///

export default class extends React.Component {
	// constructor(props) {
	// 	super(props);

	// 	this.state = {
	// 		is_loading: false,
	// 	}
	// }

	render() {
		const data = this.props.data;
		const value = _.get(data, 'input.value', null);

		if (value === null) { return null; }

		return (
			<div style={{paddingVertical: 6}}>
				<Rate
					max={5}
					value={value}
				/>
			</div>
		)
	}
}