import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {Button} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import {connect} from 'react-redux';
import {
	Link
} from 'react-router-dom';
///
import * as RemoteGroupsSearchActions from 'codb-core/actions/remote-groups-search';
import * as AppActions from 'codb-core/actions/app';
import I18n from 'codb-core/i18n';
///
import './styles.css';
import GroupRow from '../group-row';


class Screen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ''
		}
	}

	componentDidMount() {
		this.props.onSearch('');
	}

	_onChangeSearchText = (e) => {
		this.setState({search: e.target.value})
	}

	_onSubmitQuery = () => {
		const { search } = this.state;
		this.props.onSearch(search);
	}

	_onFetchMore = () => {
		this.props.onFetchMore();
	}

	render() {
		const {
			list,
			is_loading,
			is_reloading,
			is_fetch_more
		} = this.props;

		const isFinalLoading = is_loading || is_reloading || is_fetch_more;

		return (
			<div className="App">
				{/*<header className="App-header">
					<p className="slogan">
						{I18n.t('App_Slogan')}
					</p>
				</header>*/}

				<div className="container">
					<div className="home-header">
						<Paper className="search-wrap">
							<InputBase
								placeholder={I18n.t('Remote_Groups_Search_Box_Reminder')}
								className="search-input"
								onChange={this._onChangeSearchText}
								inputProps={{ 
									'aria-label': I18n.t('Remote_Groups_Search_Box_Reminder'),
								}}
							/>

							<IconButton aria-label="search" onClick={this._onSubmitQuery}>
								<SearchIcon />
							</IconButton>
						</Paper>
					</div>

					<div className="home-content">
						<div>
							{list.map((v, k) => (
								<Link key={k} to={`/db/${v.id}`} className="g-pointer g-clear-link">
									<GroupRow
										data={v}
									/>
								</Link>
									
							))}

							{(!isFinalLoading && list.length === 0) && (
								<div className="g-empty-list-reminder">{I18n.t('Remote_Groups_Search_Empty_Text')}</div>
							)}
						</div>

						<div className="g-flex-h-c-c" style={{marginTop: 10}}>
							{(is_loading || is_reloading || is_fetch_more) && (
								<CircularProgress style={{marginRight: 10}} size={24} />
							)}

							<Button variant="contained" onClick={this._onFetchMore}>
								{I18n.t('Remote_Group_Search_Fetch_More_Btn')}
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
		
}



export default connect(
	(state) => {
		return {
			list: state.remoteGroupsSearch.list,
			is_loading: state.remoteGroupsSearch.is_loading,
			is_reloading: state.remoteGroupsSearch.is_reloading,
			is_fetch_more: state.remoteGroupsSearch.is_fetch_more,
			// user_extra_info: state.remoteUser.extra_info,
			// user: state.auth.user
		}
	},
	(dispatch) => {
		return {
			onSearch: (searchTxt) => {
				dispatch(RemoteGroupsSearchActions.search(searchTxt))
			},
			onReload: () => {
				dispatch(RemoteGroupsSearchActions.reload())
			},
			onFetchMore: () => {
				dispatch(RemoteGroupsSearchActions.fetchMore())
			},
			onPopupMsg: (error) => {
				dispatch(AppActions.displayGlobalMsg(error))
			}
		}
	}
)(Screen);