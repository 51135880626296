import * as React from 'react';
import _ from 'lodash';

import './styles.css'

import ItemFilterOptionType from 'codb-core/constants/item-filter-option-type';




const ItemIcon = ({type}) => {
	switch(type) {
		case ItemFilterOptionType.BOOLEAN:
			return (
				<i className="mdi mdi-toggle-switch mdi-18 mdi-dark" aria-hidden="true"></i>
			);
		case ItemFilterOptionType.NUMBER:
			return (
				<i className="material-icons md-18 md-dark">dialpad</i>
			)
		case ItemFilterOptionType.SHORT_TEXT:
			return (
				<i className="material-icons md-18 md-dark">title</i>
			)
		case ItemFilterOptionType.LONG:
			return (
				<i className="material-icons md-18 md-dark">text_fields</i>
			)
		case ItemFilterOptionType.RELATION:
			return (
				<i className="material-icons md-18 md-dark">device_hub</i>
			)
		case ItemFilterOptionType.RATE:
			return (
				<i className="material-icons md-18 md-dark">star</i>
			)
		default:
			// throw new Error("Unknow column type")
			console.warn("Unknow column type");
			return null
	}
}


export default class extends React.Component {
	_onSelect = () => {
		this.props.onSelect(this.props.data);
	}

	_checkHasContent = () => {
		const {
			filled,
			data,
			// focus
		} = this.props;

		return !!_.find(filled, (u) => _.isEqual(data, u));
	}

	_checkFocus = () => {
		const {
			// filled,
			data,
			focus
		} = this.props;

		return _.isEqual(focus, data);
	}

	_getMainStyle = () => {
		let result = "nav-item-wrap";

		if (this._checkFocus()) {
			result += " focused"
		}

		if (this._checkHasContent()) {
			result += " has-content"
		}

		return result;
	}

	render() {
		const {
			data,
		} = this.props;

		const {
			type
		} = data;

		return (
			<div className={this._getMainStyle()}>
				<div className="nav-item-content" onClick={this._onSelect}>
					<ItemIcon type={type} />

					<b className="nav-item-content-text">{data.label}</b>
				</div>

				<div className="nav-item-deco"></div>
			</div>
		)
	}
}