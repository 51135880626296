import * as React from 'react';
// import _ from 'lodash';

// import Input from '@material-ui/core/Input';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';

// import FilterItemComparision from 'codb-core/constants/filter-item-comparison';
// import * as UtilsItemFilterOption from 'codb-core/utils/item-filter-option';
// import MyColors from 'codb-core/constants/my-theme';
import I18n from 'codb-core/i18n';
import * as UtilsGenericItem from 'codb-core/utils/generic-item';
import * as UtilsFilterUserInputRelation from 'codb-core/utils/filter-user-input-relation';



export default class extends React.Component {
	constructor(props) {
		super(props);

		this._compareOptions = UtilsFilterUserInputRelation.getCompareOptions();

		this.state = {
			// focus: -1,
			label_options: this._getLabelOptions(),
		}
	}

	_onRemove = () => {
		this.props.onChange(this.props.index, null);
	}

	_getLabelOptions = () => {
		const relatedItemsRowLabels = UtilsGenericItem.getRelatedItemsRowLabels(this.props.items, this.props.meta.label)
		// relatedItemsRowLabels.push(I18n.t('Cancel'));
		return relatedItemsRowLabels;
	}

	_onChange = (newUserInput) => {
		this.props.onChange(this.props.index, newUserInput);
	}

	_onSubmit = (compare) => {
		this.props.onChange(this.props.index, Object.assign({}, this.props.data, {
			compare
		}))
	}

	_onChangeInput = (e) => {
		this.props.onChange(this.props.index, Object.assign({}, this.props.data, {
			option: e.target.value
		}))
	}

	_onChangeCompare = (compare) => {
		this.props.onChange(this.props.index, Object.assign({}, this.props.data, {
			compare
		}))
	}

	_getInputText = () => {
		return this.props.data.option ? this.props.data.option : '';
	}

	_getLabelActions = () => {
		return this.state.label_options;
	}

	_getCompareActions = () => {
		const result = this._compareOptions.map(v => (v.text));
		// result.push(I18n.t('Cancel'));
		return result;
	}

	// _onSelectLabelAction = (idx) => {
	// 	const { label_options } = this.state;

	// 	if (idx < label_options.length -1) {
	// 		const label = label_options[idx];

	// 		this._onChange(Object.assign({}, this.props.data, {
	// 			label
	// 		}));
	// 	}

	// 	// this.setState({
	// 	// 	focus: -1
	// 	// })
	// }

	// _onSelectCompareAction = (idx) => {
	// 	if (idx < this._compareOptions.length) {
	// 		const options = this._compareOptions[idx];

	// 		let equal = false;
	// 		let larger = false;
	// 		let smaller = false;
	// 		let include = false;
	// 		let on = -1;

	// 		switch(options.id) {
	// 			case "Equal":
	// 				equal = true;
	// 				break;
	// 			case "Equal_Larger":
	// 				equal = true;
	// 				larger = true;
	// 				break;
	// 			case "Equal_Smaller":
	// 				equal = true;
	// 				smaller = true;
	// 				break;
	// 			case "Larger":
	// 				larger = true;
	// 				break;
	// 			case "Smaller":
	// 				smaller = true;
	// 				break;
	// 			case "Includes":
	// 				include = true;
	// 				break;
	// 			case "Switched_On":
	// 				on = 1;
	// 				break;
	// 			case "Switched_Off":
	// 				on = 0;
	// 				break;
	// 		}

	// 		this._onChangeCompare({
	// 			equal,
	// 			larger,
	// 			smaller,
	// 			include,
	// 			on
	// 		});
	// 	}

	// 	// this.setState({
	// 	// 	focus: -1
	// 	// })
	// }

	// _onPressLabelBlock = () => {
	// 	this._labelActionSheet.show();

	// 	this.setState({
	// 		focus: 1
	// 	})
	// }

	// _onPressCompareBlock = () => {
	// 	this._compareActionSheet.show();

	// 	this.setState({
	// 		focus: 2
	// 	})
	// }

	// _getCriteriaId = (compare) => {
	// 	return UtilsFilterUserInputRelation.convertCompareObjectToId(compare);
	// }

	_getCriteriaText = () => {
		// Reverse criterial to text
		const {data} = this.props;
		const {compare} = data;

		return UtilsFilterUserInputRelation.convertCompareObjectToText(compare);
	}

	_onSelectLabelAction = (e) => {
		const value = e.target.value;

		if (value !== '') {
			this._onChange(Object.assign({}, this.props.data, {
				label: value
			}));
		}
	}



	_onSelectCompareAction = (e) => {
		const value = e.target.value;
		if (value === '') { return; }

		const compare = UtilsFilterUserInputRelation.convertCompareIdToObject(value);
		this._onChangeCompare(compare);
	}



	render() {
		const labelActions = this._getLabelActions();
		// const compareActions = this._getCompareActions();

		const {
			data
		} = this.props;

		if (!data) { return; }

		const {
			label,
			compare
		} = data;

		// const {
		// 	focus
		// } = this.state;

		const finalLabel = label || I18n.t('Dataset_Label');
		// const finalCriteria = this._getCriteriaText();

		const finalCriteria = UtilsFilterUserInputRelation.convertCompareObjectToId(compare);

		// const datasetLabelWidth = _.has(this, '_datasetLabelRef.current.offsetWidth') ? this._datasetLabelRef.current.offsetWidth : 0

		return (
			<div className="g-flex-h-sb-c">
				<div className="g-flex-h-sb-fe">
					{/*<TouchableOpacity onPress={this._onPressLabelBlock}>
						<View style={focus === 1 ? Styles.focus_block : Styles.unfocus_block}>
							<Text>{finalLabel}</Text>
						</View>
					</TouchableOpacity>*/}

					<FormControl variant="filled">
						<InputLabel htmlFor="dataset-label">
							{I18n.t('Dataset_Label')}
						</InputLabel>

						<Select
							native
							value={finalLabel}
							onChange={this._onSelectLabelAction}
							input={
								<FilledInput name="dataset-label" id="dataset-label" />
							}
						>
							<option value="">{I18n.t('Dataset_Label')}</option>

							{labelActions.map((v, k) => (
								<option value={v} key={k}>{v}</option>
							))}
						</Select>
					</FormControl>

					{/*<TouchableOpacity onPress={this._onPressCompareBlock}>
						<View style={[{marginLeft: 10}, focus === 2 ? Styles.focus_block : Styles.unfocus_block]}>
							<Text>{finalCriteria}</Text>
						</View>
					</TouchableOpacity>*/}

					<FormControl variant="filled" style={{marginLeft: 10}}>
						<InputLabel htmlFor="dataset-compare">
							{I18n.t('Criterion')}
						</InputLabel>

						<Select
							native
							value={finalCriteria}
							onChange={this._onSelectCompareAction}
							input={
								<FilledInput name="dataset-compare" id="dataset-compare" />
							}
						>
							<option value="">{I18n.t('Criterion')}</option>

							{this._compareOptions.map((v, k) => (
								<option value={v.id} key={k}>{v.text}</option>
							))}
						</Select>
					</FormControl>

					{(compare.on !== 0 && compare.on !== 1) && (
						<div style={{marginLeft: 10}}>
							<Input
								value={this._getInputText()}
								onChange={this._onChangeInput}
								maxLength={32}
								fullWidth={true}
								/*placeholder={"Input search words here"}*/
							/>
						</div>
							
					)}

					{/*(compare.on !== 0 && compare.on !== 1) && (
						<Input
							containerStyle={{flex: 1}}
							inputStyle={{textAlign: 'center'}}
							value={this._getInputText()}
							onChangeText={this._onChangeInput}
							keyboardType={Platform.OS === 'ios' ? "numbers-and-punctuation" : 'default'}
							clearTextOnFocus={true}
							maxLength={22}
						/>
					)*/}
				</div>

				<Icon
					color="secondary"
					onClick={this._onRemove}
					className="g-pointer"
				>close</Icon>

				{/*<Icon
					name="close"
					color={MyColors.error}
					onPress={this._onRemove}
				/>

				<ActionSheet
					ref={o => this._labelActionSheet = o}
					title={I18n.t('Actions')}
					options={labelActions}
					cancelButtonIndex={labelActions.length}
					destructiveButtonIndex={labelActions.length}
					onPress={this._onSelectLabelAction}
				/>

				<ActionSheet
					ref={o => this._compareActionSheet = o}
					title={I18n.t('Actions')}
					options={compareActions}
					cancelButtonIndex={compareActions.length}
					destructiveButtonIndex={compareActions.length}
					onPress={this._onSelectCompareAction}
				/>*/}
			</div>
		)
	}
}