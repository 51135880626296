import * as React from 'react';
import _ from 'lodash';

import {
	Button,
	ButtonGroup
} from 'react-bootstrap';

import * as UtilsItemFilterOption from 'codb-core/utils/item-filter-option';
import MyColors from 'codb-core/constants/my-theme';

export default class extends React.Component {
	_getCompare = () => {
		return UtilsItemFilterOption.getCompare(this.props.selected, this.props.meta, this.props.data);
	}

	_onSubmit = (compare) => {
		if (compare.smaller || compare.equal || compare.larger) {
			this._onSelect(compare);
		} else {
			this.props.onUnselect(this.props.meta, this.props.data);
		}
	}

	_onPressSmallerSign = () => {
		const compare = this._getCompare();

		if (compare === null) {
			this._onSelect({
				smaller: true
			})
		} else {
			const newCompare = {};

			if (_.has(compare, 'smaller')) {
				newCompare.smaller = !compare.smaller;
			} else {
				newCompare.smaller = true;
			}

			newCompare.larger = false;

			if (_.has(compare, 'equal')) {
				newCompare.equal = compare.equal;
			} else {
				newCompare.equal = false;
			}

			this._onSubmit(newCompare)
		}
	}

	_onPressLargerSign = () => {
		const compare = this._getCompare();

		if (compare === null) {
			this._onSelect({
				larger: true
			})
		} else {
			const newCompare = {};

			if (_.has(compare, 'larger')) {
				newCompare.larger = !compare.larger;
			} else {
				newCompare.larger = true;
			}

			newCompare.smaller = false;

			if (_.has(compare, 'equal')) {
				newCompare.equal = compare.equal;
			} else {
				newCompare.equal = false;
			}

			this._onSubmit(newCompare)
		}
	}

	_onPressEqualSign = () => {
		const compare = this._getCompare();

		if (compare === null) {
			this._onSelect({
				equal: true
			})
		} else {
			const newCompare = {};

			if (_.has(compare, 'equal')) {
				newCompare.equal = !compare.equal;
			} else {
				newCompare.equal = true;
			}

			if (_.has(compare, 'smaller')) {
				newCompare.smaller = compare.smaller;
			} else {
				newCompare.smaller = false;
			}

			if (_.has(compare, 'larger')) {
				newCompare.larger = compare.larger;
			} else {
				newCompare.larger = false;
			}

			this._onSubmit(newCompare)
		}
	}

	_onSelect = (compare) => {
		this.props.onSelect(
			this.props.meta,
			this.props.data,
			compare
		);
	}

	// _onPressSign = (index) => {
	// 	switch (index) {
	// 		case 0:
	// 			this._onPressSmallerSign();
	// 			break;
	// 		case 1:
	// 			this._onPressEqualSign();
	// 			break;
	// 		case 2:
	// 			this._onPressLargerSign();
	// 			break;
	// 	}
	// }

	_getSelectedIndexes = () => {
		const result = [];

		const compare = this._getCompare();

		if (compare !== null) { 
			if (compare.smaller) {
				result.push(0)
			}
			if (compare.equal) {
				result.push(1)
			}
			if (compare.larger) {
				result.push(2)
			}
		}

		return result;
	}

	render() {
		const compare = this._getCompare();

		return (
			<div className="g-flex-h-sb-c">
				<ButtonGroup>
					<Button onClick={this._onPressSmallerSign} variant="secondary" style={{backgroundColor: (compare && compare.smaller) ? MyColors.accent : MyColors.gray_light}}>{"<"}</Button>
					<Button onClick={this._onPressEqualSign} variant="secondary" style={{backgroundColor: (compare && compare.equal) ? MyColors.accent : MyColors.gray_light}}>{"="}</Button>
					<Button onClick={this._onPressLargerSign} variant="secondary" style={{backgroundColor: (compare && compare.larger) ? MyColors.accent : MyColors.gray_light}}>{">"}</Button>
				</ButtonGroup>

				<div>{this.props.data}</div>
			</div>
		)
	}
}