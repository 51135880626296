import * as React from 'react';

// import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
// import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText';

import {
	Button,
	ButtonGroup
} from 'react-bootstrap';

import _ from 'lodash';
import Validator from 'validator';

import Rate from '../rate';
///
import MyColors from 'codb-core/constants/my-theme';



export default class extends React.Component {
	constructor(props) {
		super(props);

		const {data} = props;

		this.state = {
			input: _.has(data, 'option') ? data.option + '' : ''
		}
	}

	_getCompare = () => {
		return this.props.data.compare;
	}

	_onRemove = () => {
		this.props.onChange(this.props.index, null);
	}

	_onSubmit = (compare) => {
		this.props.onChange(this.props.index, Object.assign({}, this.props.data, {
			compare
		}))
	}

	_onPressSmallerSign = () => {
		const compare = this._getCompare();
		const newCompare = {};

		if (_.has(compare, 'smaller')) {
			newCompare.smaller = !compare.smaller;
		} else {
			newCompare.smaller = true;
		}

		newCompare.larger = false;

		if (_.has(compare, 'equal')) {
			newCompare.equal = compare.equal;
		} else {
			newCompare.equal = false;
		}

		this._onSubmit(newCompare)
	}

	_onPressLargerSign = () => {
		const compare = this._getCompare();
		const newCompare = {};

		if (_.has(compare, 'larger')) {
			newCompare.larger = !compare.larger;
		} else {
			newCompare.larger = true;
		}

		newCompare.smaller = false;

		if (_.has(compare, 'equal')) {
			newCompare.equal = compare.equal;
		} else {
			newCompare.equal = false;
		}

		this._onSubmit(newCompare)
	}

	_onPressEqualSign = () => {
		const compare = this._getCompare();
		const newCompare = {};

		if (_.has(compare, 'equal')) {
			newCompare.equal = !compare.equal;
		} else {
			newCompare.equal = true;
		}

		if (_.has(compare, 'smaller')) {
			newCompare.smaller = compare.smaller;
		} else {
			newCompare.smaller = false;
		}

		if (_.has(compare, 'larger')) {
			newCompare.larger = compare.larger;
		} else {
			newCompare.larger = false;
		}

		this._onSubmit(newCompare)
	}

	_getSelectedIndexes = () => {
		const result = [];

		const compare = this._getCompare();

		if (compare !== null) { 
			if (compare.smaller) {
				result.push(0)
			}
			if (compare.equal) {
				result.push(1)
			}
			if (compare.larger) {
				result.push(2)
			}
		}

		return result;
	}

	_onChangeInput = (e) => {
		const txt = e.target.value;

		if (Validator.isNumeric(txt)) {
			this.props.onChange(this.props.index, Object.assign({}, this.props.data, {
				option: _.toNumber(txt)
			}));
		}			

		this.setState({
			input: txt
		})
	}

	_getInputText = () => {
		return this.state.input;
	}

	_onChangeRate = (value) => {
		this.props.onChange(this.props.index, Object.assign({}, this.props.data, {
			option: value
		}))
	} 

	_getRateValue = () => {
		return _.get(this.props.data, 'option', 0);
	}

	render() {
		// const input = this._getInputText();
		const compare = this._getCompare();
		// const hasError = !(Validator.isNumeric(input) || input === '');

		return (
			<div className="g-flex-h-sb-c">
				<div className="g-flex-h-fs-c" style={{marginRight: 20, marginBottom: 10}}>
					<ButtonGroup style={{marginRight: 10}}>
						<Button onClick={this._onPressSmallerSign} variant="secondary" style={{backgroundColor: (compare && compare.smaller) ? MyColors.accent : MyColors.gray_light}}>{"<"}</Button>
						<Button onClick={this._onPressEqualSign} variant="secondary" style={{backgroundColor: (compare && compare.equal) ? MyColors.accent : MyColors.gray_light}}>{"="}</Button>
						<Button onClick={this._onPressLargerSign} variant="secondary" style={{backgroundColor: (compare && compare.larger) ? MyColors.accent : MyColors.gray_light}}>{">"}</Button>
					</ButtonGroup>

					{/*<FormControl error={hasError} style={{marginLeft: 10}}>
						<Input
							style={{textAlign: 'center'}}
							value={input}
							onChange={this._onChangeInput}
							maxLength={32}
						/>

						{hasError && (
							<FormHelperText id="input-error-text">{I18n.t('Invalid_Input')}</FormHelperText>
						)}
					</FormControl>*/}

					<Rate
						max={5}
						value={this._getRateValue()}
						onChange={this._onChangeRate}
					/>
				</div>

				<Icon
					color="error"
					onClick={this._onRemove}
					className="g-pointer"
				>close</Icon>
			</div>
		)
	}
}