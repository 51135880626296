import * as React from 'react';

// import _ from 'lodash';

import './short-text.css';
import * as UtilsItemFilterOption from 'codb-core/utils/item-filter-option';

export default class extends React.Component {
	// _getCompare = () => {
	// 	const found = this._getSelected();
	// 	return !!found ? found.compare : null;
	// }

	// _getSelected = () => {
	// 	return _.find(this.props.selected, (u) => {
	// 		return _.isEqual(u.meta, this.props.meta) && _.isEqual(u.option, this.props.data);
	// 	});
	// }

	_getCompare = () => {
		return UtilsItemFilterOption.getCompare(this.props.selected, this.props.meta, this.props.data);
	}

	_onPress = () => {
		const compare = this._getCompare();

		if (compare !== null) {
			this.props.onUnselect(this.props.meta, this.props.data);
		} else {
			this._onSelect({
				equal: true,
				include: false
			})
		}
	}

	_onSelect = (compare) => {
		this.props.onSelect(
			this.props.meta,
			this.props.data,
			compare
		);
	}

	_getChecked = () => {
		const compare = this._getCompare();
		// if (compare === null) { return 'indeterminate'; }
		// return 'checked';

		// if (compare === null) { return 'check_box_outline'; }
		// return 'check_box'

		if (compare && compare.equal) {
			return true;
		}

		return false;
	}

	render() {
		const isSelected = this._getChecked();

		return (
			<div
				className="short-text-wrap"
				onClick={this._onPress}
			>
				<div>{this.props.data}</div>

				{isSelected && (
					<i className="material-icons md-accent">check_box</i>
				)}

				{!isSelected && (
					<i className="material-icons md-dark">check_box_outline_blank</i>
				)}
				
			</div>
		)
	}
}