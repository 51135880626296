import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import { combineReducers } from 'redux';
///
import auth from 'codb-core/reducers/auth';
import app from 'codb-core/reducers/app';
import draftGroups from 'codb-core/reducers/draft-groups';
import draftItems from 'codb-core/reducers/draft-items';
import draftGroupFilter from 'codb-core/reducers/draft-group-filter';
import rowSettingsEdit from 'codb-core/reducers/row-settings-edit';
import draftColumnEdit from 'codb-core/reducers/draft-column-edit';
import draftGroupUpload from 'codb-core/reducers/draft-group-upload';
import remoteGroups from 'codb-core/reducers/remote-groups';
import remoteUser from 'codb-core/reducers/remote-user';
import remoteItems from 'codb-core/reducers/remote-items';
import remoteItemsFilter from 'codb-core/reducers/remote-items-filter';
import remoteGroupsSearch from 'codb-core/reducers/remote-groups-search';
import remoteFollowedGroups from 'codb-core/reducers/remote-followed-groups';
import remoteFollowedItems from 'codb-core/reducers/remote-followed-items';
import remoteItemReview from 'codb-core/reducers/remote-item-review';
import myContributions from 'codb-core/reducers/my-contributions';
import remoteGroupSelector from 'codb-core/reducers/remote-group-selector';
///

const loggerMiddleware = createLogger();
let _store = null;

export default function(initialState={}) {
	if (!!_store) { return _store; }

	const rootReducer = combineReducers({
		draftGroupFilter,
		draftItems,
		draftGroups,
		rowSettingsEdit,
		draftColumnEdit,
		draftGroupUpload,
		remoteGroups,
		remoteUser,
		remoteItems,
		remoteItemsFilter,
		remoteGroupsSearch,
		remoteFollowedGroups,
		remoteFollowedItems,
		remoteItemReview,
		myContributions,
		remoteGroupSelector,
		app,
		auth
	});

	const store = createStore(
		rootReducer,
		initialState,
		applyMiddleware(
			thunkMiddleware,
			// loggerMiddleware // Open for displaying all logs
		)
	);

	_store = store;
	return _store;
}