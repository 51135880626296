import * as React from 'react';
import _ from 'lodash';

import Checkbox from '@material-ui/core/Checkbox';

import logo from '../../assets/ontology-icon-64.png';

import * as UtilsItemFilterOption from 'codb-core/utils/item-filter-option';

export default class extends React.Component {
	_getCompare = () => {
		return UtilsItemFilterOption.getCompare(this.props.selected, this.props.meta, this.props.data);
	}

	_onPress = () => {
		const compare = this._getCompare();

		if (compare !== null) {
			this.props.onUnselect(this.props.meta, this.props.data);
		} else {
			this._onSelect({
				equal: true,
			})
		}
	}

	_onSelect = (compare) => {
		this.props.onSelect(
			this.props.meta,
			this.props.data,
			compare
		);
	}

	_getChecked = () => {
		const compare = this._getCompare();
		// if (compare === null) { return 'indeterminate'; }
		// return 'checked';

		return !!compare;
	}

	_getItemData = () => {
		const {
			data,
			items
		} = this.props;

		const {
			target_id,
			// target_type
		} = data;

		return _.find(items, {id: target_id});
	}

	render() {
		const itemData = this._getItemData();
		if (!itemData) { return null; }

		const {
			icon,
			title
		} = itemData;

		return (
			<div className="g-flex-h-sb-c g-padding-6-10 g-pointer" onClick={this._onPress}>
				<div className="g-flex-h-fs-c">
					<img
						src={(icon && icon.uri) ? icon.uri : logo}
						width="32"
						height="32"
						style={{borderRadius: 4, marginRight: 10}}
						onError={(e)=>{e.target.onerror = null; e.target.src=logo}}
						alt="Connected Item"
					/>

					<b>{title}</b>
				</div>

				<Checkbox
					checked={this._getChecked()}
					color="primary"
				/>
			</div>
		)
	}
}