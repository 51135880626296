import React from 'react';
import _ from 'lodash';

import {
	Container,
	Modal
} from 'react-bootstrap';

import {
	Link
} from 'react-router-dom';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

// import API from '../../api';
import API from 'codb-core/api';
import {Dark} from 'codb-core/constants/my-theme';
import I18n from 'codb-core/i18n';
import * as UtilsRemoteItem from 'codb-core/utils/remote-item';
///
import logo from '../../assets/ontology-icon-64.png';
import './styles.css';
import RowForDisplay from '../row-for-display';
import LinkToApp from '../link-to-app';


export default class extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			item: null,
			is_gallery_open: false,
			gallery_idx: 0
		}
	}
	
	componentDidMount() {
		this._hook();
	}

	componentWillUnmount() {
		this._unhook();
	}

	componentDidUpdate(prevProps, prevState) {
		if (_.has(this.props, 'match.params.id') && _.has(prevProps, 'match.params.id')) {
			if (this.props.match.params.id !== prevProps.match.params.id) {
				this._unhook();
				this._hook();
			}
		}
	}

	_hook = () => {
		const id = this._getId();

		this._unsubscribe = API.Items.getRef(id).onSnapshot((snap) => {
			const data =snap.data();

			if (data) {
				this.setState({
					item: Object.assign({}, data, {
						id: snap.id
					})
				})
			} else {
				this.setState({
					item: null
				})
			}
		});
	}

	_unhook = () => {
		if (this._unsubscribe) {
			this._unsubscribe();
		}
	}

	_getId = () => {
		if (_.has(this.props, 'match.params.id')) {
			return this.props.match.params.id;
		} else {
			return null;
		}
	}

	_openGallery = (idx) => {
		this.setState({
			is_gallery_open: true,
			gallery_idx: idx
		})
	}

	_onCloseGallery = () => {
		this.setState({
			is_gallery_open: false
		})
	}

	_gotoPrevImg = () => {

	}

	_gotoNextImg = () => {

	}

	render() {
		const {
			item,
			is_gallery_open,
		} = this.state;

		if (!item) {
			return null;
		}

		const {
			icon,
			title,
			subtitle,
			imgs,
			rows,
			group_id
		} = item;

		const iconSrc = icon ? icon.uri : logo;

		let galleryImgs;

		if (imgs.length > 0) {
			galleryImgs = imgs.map((v) => ({
				original: v.uri,
				thumbnail: v.uri,
				originalClass: 'gallery-original-img'
			}));
		}

		return (
			<Container>
				<div className="g-margin-t-10">
					<LinkToApp link={UtilsRemoteItem.getAppShareLink(item)} />
				</div>
				

				<div className="wrapper">
					<Link className="my-header g-pointer g-clear-link" to={`/db/${group_id}`}>
						<i className="material-icons md-24 md-light">navigate_before</i>

						<b className="my-header-text">{I18n.t('Back_to_Group')}</b>
					</Link>

					<div className="overview">
						<img
							src={iconSrc}
							className="overview-icon"
							alt=""
						/>

						<div className="overview-info">
							<div style={{color: Dark.text, "paddingHorizontal": 10}}>{title}</div>

							{!!subtitle && (
								<div style={{color: Dark.text}}>{subtitle}</div>
							)}
						</div>
					</div>

					{imgs.length > 0 && (
						<div className="gallery">
							<div className="gallery-caption">{I18n.t('Gallery')}</div>

							<div className="gallery-content">
								{imgs.map((v, k) => (
									<img
										src={v.uri}
										key={k}
										alt=""
										className="gallery-img"
										onClick={() => this._openGallery(k)}
									/>
								))}
							</div>
						</div>
					)}

					{rows.length > 0 && (
						<div className="my-rows">
							<div className="myrows-caption">{I18n.t('Datasets')}</div>

							<div className="my-rows-content">
								{rows.map((v, k) => (
									<RowForDisplay data={v} key={k} />
								))}
							</div>
						</div>
					)}
				</div>

				<Modal 
					show={is_gallery_open}
					size="xl"
					centered
					dialogClassName="modal-90w"
					onHide={this._onCloseGallery}
					className="my-modal"
				>
					<Modal.Body className="modal-image-gallery">
						<ImageGallery 
							items={galleryImgs} 
							showThumbnails={false}
							showFullscreenButton={false}
							showPlayButton={false}
						/>
					</Modal.Body>
				</Modal>
						
					
			</Container>
		)
	}
};