import React from 'react';

import './styles.css';
import GooglePlayLogo from '../../assets/google-play-logo-176x64.png';
import AppStoreLogo from '../../assets/app-store-logo-150x150.png';

import I18n from 'codb-core/i18n';
import {
	APP_LINK_GOOGLE_PLAY,
	APP_LINK_IOS
} from 'codb-core/configs/app';





export default function() {
	if (!APP_LINK_GOOGLE_PLAY && !APP_LINK_IOS) { return null; }
	return (
		<div className="promotion-page">
			<div className="promotion-text">{I18n.t('Promote_App_Text')}</div>

			<div className="g-flex-h-c-c g-margin-t-10">
				{!!APP_LINK_GOOGLE_PLAY && (
					<a target="_blank" href={APP_LINK_GOOGLE_PLAY} rel="noopener noreferrer">
						<img
							src={GooglePlayLogo}
							height="44"
							alt="Google Play"
						/>
					</a>
				)}

				{!!APP_LINK_IOS && (
					<a target="_blank" href={APP_LINK_IOS} rel="noopener noreferrer">
						<img
							src={AppStoreLogo}
							height="44"
							alt="Google Play"
							style={{marginLeft: 20}}
						/>
					</a>
				)}
			</div>
		</div>
	);
}