import * as React from 'react';

import Icon from '@material-ui/core/Icon';

import './styles.css';

export default function({
	value,
	onValueChange
}) {
	return (
		<div onClick={() => onValueChange(!value)} className="g-pointer switch-btn-wrap">
			<Icon style={{color: !value ? 'red' : '#ccc'}}>close</Icon>

			<Icon style={{color: value ? 'green' : '#ccc', marginLeft: 4}}>check</Icon>
		</div>
	)
}