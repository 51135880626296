import React from 'react';
import ReactDOM from 'react-dom';

import { Provider as ReduxProvider } from 'react-redux';
import 'normalize.css/normalize.css';
import '@mdi/font/css/materialdesignicons.min.css';

import * as Core from 'codb-core';
import API from 'codb-core/api';
import {setup as I18nSetup} from 'codb-core/i18n';

import Store from './store';

import './index.css';
import App from './screens/app';

import * as serviceWorker from './serviceWorker';


const store = Store();
API.setup();
I18nSetup({
	locale: navigator.language || navigator.userLanguage
});
///
Core.setPlatformAsWeb();



ReactDOM.render(
	<ReduxProvider store={store}>
		<App />
	</ReduxProvider>,

	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
