import React from 'react';

import ColumnType from 'codb-core/constants/column-type';
import {Dark} from 'codb-core/constants/my-theme';
import I18n from 'codb-core/i18n';

import Relation from '../column-for-display-relation';
import ForShortText from '../column-for-display-short-text';
import Rate from '../column-for-display-rate';

const Text = ({data}) => {
	return <div style={{color: 'white'}}>{data.input}</div>
}

const BooleanComp = ({data}) => {
	const {
		input
	} = data;

	const iconName = !!input ? 'check' : 'close';
	const iconColor = !!input ? Dark.green : Dark.red;

	return (
		<div>
			<i className="material-icons md-36 md-light" style={{color: iconColor}}>{iconName}</i>
		</div>
	)
}


export default function ({data}) {
	if (!data) { return null; }

	const {
		type
	} = data;

	switch(type) {
		case ColumnType.SHORT: {
			return <ForShortText data={data} />
		}
		case ColumnType.LONG:
		case ColumnType.NUMBER: {
			return <Text data={data} />
		}

		case ColumnType.BOOLEAN: {
			return <BooleanComp data={data} />
		}

		case ColumnType.RELATION: {
			return <Relation data={data} />
		}

		case ColumnType.RATE: {
			return <Rate data={data} />
		}

		default: {
			return (
				<div style={{padding: 10}}>
					{I18n.t('Column_Display_Unknown_Type_Reminder')}
				</div>
			)
		}
	}
}