import React from 'react';
import _ from 'lodash';

import {
	Link
} from 'react-router-dom';

import API from 'codb-core/api';
///
import ItemRow from '../item-row';

export default class extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			item_data: null
		}
	}

	async componentDidMount() {
		const targetId = this._getTargetId();

		if (targetId) {
			this.setState({
				is_loading: true
			})

			const itemData = await API.Items.getById(targetId);

			if (itemData) {
				this.setState({
					item_data: itemData,
					is_loading: false
				})
			}
		}
	}

	_getTargetId = () => {
		if (_.has(this.props, 'data.input.target_id')) {
			const [targetId] = _.at(this.props, 'data.input.target_id');
			return targetId;
		} else {
			return null;
		}
	}

	render() {
		const {
			item_data
		} = this.state;

		if (!item_data) { return null; }

		return (
			<div>
				<Link className="g-pointer g-clear-link" to={`/item/${item_data.id}`} >
					<ItemRow data={item_data} />
				</Link>
			</div>
		)
	}
}